import { Box, Container, Grid, Link, Typography } from "@mui/material";
import "./Footer.css";
import FooterLogo from "./../../assets/images/logo-footer.svg";
function Footer() {
  return (
    <Box
      sx={{
        bgcolor: "#0C3DC4",
        paddingY: "24px",
      }}
      component="footer"
    >
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item md={6}>
            <Box component="div" sx={{ paddingTop: "46px" }}>
              <img src={FooterLogo} alt="AVA Group Logo"></img>
            </Box>
            <Box component="div" sx={{ marginY: "24px" }}>
              <Grid container spacing={2}>
                <Grid item md={5}>
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: "Poppins, sans-serif",
                      color: "#fff",
                      fontSize: "20px",
                      fontWeight: "300",
                      margin: "0 auto",
                    }}
                  >
                    Centennial, Colorado
                  </Typography>

                  <Link
                    href="mailto:hr@avagrouptech.com"
                    underline="none"
                    sx={{ color: "#FFF", fontSize: "20px", fontWeight: 400 }}
                  >
                    hr@avagrouptech.com
                  </Link>
                  <br />
                  <Link
                    href="tel:=+1(123) 456 7890"
                    underline="none"
                    sx={{ color: "#FFF", fontSize: "20px", fontWeight: 400 }}
                  >
                    +1720 952 1904
                  </Link>
                </Grid>
                <Grid
                  item
                  md={7}
                  sx={{ color: "#FFF", fontSize: "20px", fontWeight: 400 }}
                >
                  &copy; Copyright 2023 AVA Group Tech. <br />
                  All Rights Reserved.
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item md={2}>
            <Box
              component="div"
              sx={{
                color: "#fff",
                fontFamily: "Poppins, sans-serif",
                fontWeight: 500,
                fontSize: "24px",
                lineHeight: "24px",
              }}
            >
              What
              <Box
                component="div"
                sx={{
                  background: "#FFAE42",
                  width: "50px",
                  height: "3px",
                  marginY: "16px",
                }}
              ></Box>
            </Box>
            <Box
              sx={{
                color: "#fff",
                display: "flex",
                flexDirection: "column",
                marginTop: "12px",
              }}
            >
              <Link
                href="/services"
                underline="none"
                sx={{
                  color: "#fff",
                  fontWeight: 400,
                  fontSize: "20px",
                  lineHeight: "24px",
                  paddingY: "6px",
                }}
              >
                {"Services"}
              </Link>
              {/* <Link
                href="#"
                underline="none"
                sx={{
                  color: "#fff",
                  fontWeight: 400,
                  fontSize: "20px",
                  lineHeight: "24px",
                  paddingY: "6px",
                }}
              >
                {"Our Work"}
              </Link>
              <Link
                href="#"
                underline="none"
                sx={{
                  color: "#fff",
                  fontWeight: 400,
                  fontSize: "20px",
                  lineHeight: "24px",
                  paddingY: "6px",
                }}
              >
                {"Terms & Conditions"}
              </Link>
              <Link
                href="#"
                underline="none"
                sx={{
                  color: "#fff",
                  fontWeight: 400,
                  fontSize: "20px",
                  lineHeight: "24px",
                  paddingY: "6px",
                }}
              >
                {"Privacy Policy"}
              </Link> */}
            </Box>
          </Grid>
          <Grid item md={2}>
            <Box
              component="div"
              sx={{
                color: "#fff",
                fontFamily: "Poppins, sans-serif",
                fontWeight: 500,
                fontSize: "24px",
                lineHeight: "24px",
              }}
            >
              Who
              <Box
                component="div"
                sx={{
                  background: "#FFAE42",
                  width: "50px",
                  height: "3px",
                  marginY: "16px",
                }}
              ></Box>
            </Box>
            <Box
              sx={{
                color: "#fff",
                display: "flex",
                flexDirection: "column",
                marginTop: "12px",
              }}
            >
              <Link
                href="/about"
                underline="none"
                sx={{
                  color: "#fff",
                  fontWeight: 400,
                  fontSize: "20px",
                  lineHeight: "24px",
                  paddingY: "6px",
                }}
              >
                {"About"}
              </Link>
              {/* <Link
                href="#"
                underline="none"
                sx={{
                  color: "#fff",
                  fontWeight: 400,
                  fontSize: "20px",
                  lineHeight: "24px",
                  paddingY: "6px",
                }}
              >
                {"Team"}
              </Link>
              <Link
                href="#"
                underline="none"
                sx={{
                  color: "#fff",
                  fontWeight: 400,
                  fontSize: "20px",
                  lineHeight: "24px",
                  paddingY: "6px",
                }}
              >
                {"Jobs"}
              </Link> */}
            </Box>
          </Grid>
          <Grid item md={2}>
            <Box
              component="div"
              sx={{
                color: "#fff",
                fontFamily: "Poppins, sans-serif",
                fontWeight: 500,
                fontSize: "24px",
                lineHeight: "24px",
              }}
            >
              Connect
              <Box
                component="div"
                sx={{
                  background: "#FFAE42",
                  width: "50px",
                  height: "3px",
                  marginY: "16px",
                }}
              ></Box>
            </Box>
            <Box
              sx={{
                color: "#fff",
                display: "flex",
                flexDirection: "column",
                marginTop: "12px",
              }}
            >
              <Link
                href="#"
                underline="none"
                sx={{
                  color: "#fff",
                  fontWeight: 400,
                  fontSize: "20px",
                  lineHeight: "24px",
                  paddingY: "6px",
                }}
              >
                {"Linkedin"}
              </Link>
              {/* <Link
                href="#"
                underline="none"
                sx={{
                  color: "#fff",
                  fontWeight: 400,
                  fontSize: "20px",
                  lineHeight: "24px",
                  paddingY: "6px",
                }}
              >
                {"Twitter"}
              </Link> */}
              <Link
                href="/contact"
                underline="none"
                sx={{
                  color: "#fff",
                  fontWeight: 400,
                  fontSize: "20px",
                  lineHeight: "24px",
                  paddingY: "6px",
                }}
              >
                {"Contact"}
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Footer;
