import { Box, Button, Container, Grid, Typography } from "@mui/material";
import "./Home.css";
import HomeBanner from "./../../assets/images/home-banner.png";
import Personalised from "./../../assets/images/personalised.svg";
import Extensive from "./../../assets/images/extensive.svg";
import Expert from "./../../assets/images/expert.svg";
import Holistic from "./../../assets/images/holistic.svg";
import { Link } from "react-router-dom";

function Home() {
  return (
    <Box
      sx={{
        bgcolor: "#FFF",
      }}
      component="div"
      className="home-banner"
    >
      <Box component="div" sx={{ bgcolor: "#0C3DC4", paddingY: "48px" }}>
        <Container maxWidth="xl">
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item md={6}>
              <Typography
                variant="h1"
                sx={{
                  color: "#FFF",
                  fontSize: "54px",
                  fontWeight: 600,
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Welcome to <br />
                AVA Group Tech
                <Box
                  component="div"
                  sx={{
                    background: "#FFAE42",
                    width: "35%",
                    height: "4px",
                    marginY: "12px",
                  }}
                ></Box>
              </Typography>
              <Typography
                variant="h6"
                sx={{ fontSize: "26px", fontWeight: 300, paddingY: "12px" }}
              >
                Unlock Your Career Potential with Our <br />
                Expert Guidance.
              </Typography>

              <Button
              component={Link}
              to="/services"
                sx={{
                  color: "#0C3DC4",
                  fontSize: "14px",
                  fontWeight: 600,
                  bgcolor: "#FFF",
                  marginY: "12px",
                  paddingY: "16px",
                  paddingX: "40px",
                  fontFamily: "Poppins, sans-serif",
                  '&:hover': {
                    backgroundColor: '#FFF',
                    color: '#0C3DC4'
                  },
                }}
              >
                EXPERIENCE OUR SERVICES
              </Button>
              
            </Grid>
            <Grid item md={6}>
              <img
                src={HomeBanner}
                alt="AVA Banner"
                style={{ maxWidth: "100%" }}
              ></img>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Container maxWidth="xl" sx={{ paddingY: "48px", textAlign: "center" }}>
        <Typography
          variant="h1"
          sx={{
            color: "#0C3DC4",
            fontSize: "32px",
            fontWeight: 500,
            fontFamily: "Poppins, sans-serif",
          }}
        >
          Are you ready to take the next step in your career journey?
          <Box
            component="div"
            sx={{
              background: "#FFAE42",
              width: "35%",
              height: "4px",
              margin: "24px auto",
              textAlign: "center",
            }}
          ></Box>
        </Typography>
        <Typography
          variant="h6"
          sx={{ fontSize: "24px", fontWeight: 300, color: "#000" }}
        >
          At AVA Group, we're dedicated to helping you reach new
          heights by connecting you with the right opportunities and providing
          expert career advice. Whether you're a fresh graduate, a seasoned
          professional, or considering a career change, our team of experienced
          consultants is here to guide you every step of the way.
        </Typography>
      </Container>
      <Box
        component="div"
        sx={{
          background: "#ddd",
          width: "100%",
          height: "1px",
        }}
      ></Box>
      <Container maxWidth="xl" sx={{ paddingY: "48px" }}>
        <Grid container spacing={2}>
          <Grid item md>
            <Typography
              variant="h6"
              sx={{
                fontSize: "32px",
                fontFamily: "Poppins, sans-serif",
                color: "#0C3DC4",
                fontWeight: 600,
              }}
            >
              Our Special features for you.
            </Typography>
            <Typography
              variant="h6"
              sx={{
                fontSize: "20px",
                fontFamily: "Poppins, sans-serif",
                color: "#0C3DC4",
                paddingY: "16px",
                fontWeight: 400,
              }}
            >
              We provide special features for all of you.
            </Typography>
          </Grid>
          <Grid item md>
            <Box
              sx={{
                border: 1,
                borderBlockStyle: "solid",
                borderColor: "#ddd",
                padding: "18px",
              }}
            >
              <Box component="div" sx={{ marginY: "24px" }}>
                <img
                  src={Personalised}
                  alt="features"
                  style={{ maxWidth: "100%", width: "50px", height: "45px" }}
                ></img>
              </Box>
              <Box component="div">
                <Typography
                  variant="h1"
                  sx={{
                    color: "#000",
                    fontSize: "18px",
                    fontWeight: 500,
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  Personalized Approach
                  <Box
                    component="div"
                    sx={{
                      background: "#FFAE42",
                      width: "35%",
                      height: "4px",
                      marginY: "16px",
                    }}
                  ></Box>
                </Typography>

                <Typography
                  variant="h1"
                  sx={{
                    color: "#000",
                    fontSize: "14px",
                    fontWeight: 300,
                    fontFamily: "Poppins, sans-serif",
                    height: "99px",
                  }}
                >
                  We understand that every individual has unique goals and
                  aspirations. Our tailored solutions ensure that your career
                  path aligns with your ambitions.
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item md>
            <Box
              sx={{
                border: 1,
                borderBlockStyle: "solid",
                borderColor: "#ddd",
                padding: "18px",
              }}
            >
              <Box component="div" sx={{ marginY: "24px" }}>
                <img
                  src={Extensive}
                  alt="features"
                  style={{ maxWidth: "100%" }}
                ></img>
              </Box>
              <Box component="div">
                <Typography
                  variant="h1"
                  sx={{
                    color: "#000",
                    fontSize: "18px",
                    fontWeight: 500,
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  Extensive Network
                  <Box
                    component="div"
                    sx={{
                      background: "#FFAE42",
                      width: "35%",
                      height: "4px",
                      marginY: "16px",
                    }}
                  ></Box>
                </Typography>

                <Typography
                  variant="h1"
                  sx={{
                    color: "#000",
                    fontSize: "14px",
                    fontWeight: 300,
                    fontFamily: "Poppins, sans-serif",
                    height: "99px",
                  }}
                >
                  With years of industry experience, we've built strong
                  relationships with top companies across various sectors,
                  giving you access to exclusive job openings.
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item md>
            <Box
              sx={{
                border: 1,
                borderBlockStyle: "solid",
                borderColor: "#ddd",
                padding: "18px",
              }}
            >
              <Box component="div" sx={{ marginY: "24px" }}>
                <img
                  src={Expert}
                  alt="features"
                  style={{ maxWidth: "100%" }}
                ></img>
              </Box>
              <Box component="div">
                <Typography
                  variant="h1"
                  sx={{
                    color: "#000",
                    fontSize: "18px",
                    fontWeight: 500,
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  Expert Guidance
                  <Box
                    component="div"
                    sx={{
                      background: "#FFAE42",
                      width: "35%",
                      height: "4px",
                      marginY: "16px",
                    }}
                  ></Box>
                </Typography>

                <Typography
                  variant="h1"
                  sx={{
                    color: "#000",
                    fontSize: "14px",
                    fontWeight: 300,
                    fontFamily: "Poppins, sans-serif",
                    height: "99px",
                  }}
                >
                  Our team of consultants brings a wealth of knowledge and
                  expertise to the table. From resume optimization to interview
                  preparation, we've got you covered.
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item md>
            <Box
              sx={{
                border: 1,
                borderBlockStyle: "solid",
                borderColor: "#ddd",
                padding: "18px",
              }}
            >
              <Box component="div" sx={{ marginY: "24px" }}>
                <img
                  src={Holistic}
                  alt="features"
                  style={{ maxWidth: "100%" }}
                ></img>
              </Box>
              <Box component="div">
                <Typography
                  variant="h1"
                  sx={{
                    color: "#000",
                    fontSize: "18px",
                    fontWeight: 500,
                    fontFamily: "Poppins, sans-serif",
                  }}
                >
                  Hollistic Support
                  <Box
                    component="div"
                    sx={{
                      background: "#FFAE42",
                      width: "35%",
                      height: "4px",
                      marginY: "16px",
                    }}
                  ></Box>
                </Typography>

                <Typography
                  variant="h1"
                  sx={{
                    color: "#000",
                    fontSize: "14px",
                    fontWeight: 300,
                    fontFamily: "Poppins, sans-serif",
                    height: "99px",
                  }}
                >
                  Beyond job placements, we offer continuous support to help you
                  grow professionally, making AVA your lifelong career partner.
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Box
        component="div"
        sx={{
          background: "#ddd",
          width: "100%",
          height: "1px",
        }}
      ></Box>

      <Container maxWidth="xl" sx={{ paddingY: "48px", textAlign: "center" }}>
        <Typography
          variant="h6"
          sx={{
            fontFamily: "Poppins, sanserif",
            textAlign: "center",
            paddingBottom: "12px",
            color: "#000",
            fontSize: "24px",
            fontWeight: "300",
            width: "75%",
            margin: "0 auto",
          }}
        >
          Ready to embark on an exciting journey toward career success? Explore
          our services and take the first step today!
        </Typography>
        <Button
        component={Link}
        to="/services"
          sx={{
            color: "#FFF",
            fontSize: "14px",
            fontWeight: 600,
            bgcolor: "#0C3DC4",
            marginY: "12px",
            paddingY: "16px",
            paddingX: "40px",
            fontFamily: "Poppins, sans-serif",
            textAlign: "center",
            '&:hover': {
              backgroundColor: '#0C3DC4',
              color: '#FFF'
            },
          }}
        >
          EXPLORE OUR SERVICES
        </Button>
      </Container>
    </Box>
  );
}

export default Home;
