import { Box, Button, Container, Grid, Typography } from "@mui/material";
import "./Services.css";
import ServicesBanner from "./../../assets/images/services-banner.png";
import Guidance from "./../../assets/images/guidance.png";
import Optimization from "./../../assets/images/optimization.png";
import JobSearch from "./../../assets/images/jobsearch.png";
import Preparation from "./../../assets/images/preparation.png";
import Strategies from "./../../assets/images/strategies.png";
import Development from "./../../assets/images/development.png";
import { Link } from "react-router-dom";

function Services() {
  return (
    <Box
      sx={{
        bgcolor: "#FFF",
      }}
      component="div"
      className="services-banner"
    >
      <Box component="div" sx={{ bgcolor: "#FFF", paddingY: "48px" }}>
        <Container maxWidth="xl">
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item md={6}>
              <Typography
                variant="h6"
                sx={{
                  color: "#0C3DC4",
                  fontSize: "24px",
                  fontWeight: 500,
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                OUR SERVICES
              </Typography>
              <Typography
                variant="h1"
                sx={{
                  color: "#000",
                  fontSize: "54px",
                  fontWeight: 600,
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Unlocking Opportunities, Empowering Careers
                <Box
                  component="div"
                  sx={{
                    background: "#FFAE42",
                    width: "35%",
                    height: "4px",
                    marginY: "12px",
                  }}
                ></Box>
              </Typography>
              <Typography
                variant="h6"
                sx={{ fontSize: "26px",fontFamily: "Poppins, sans-serif", fontWeight: 300, paddingY: "12px" }}
              >
                At AVA Group, we offer a range of comprehensive
                services designed to empower your career growth. Our goal is to
                equip you with the tools, resources, and opportunities needed to
                excel in your chosen field.
              </Typography>
            </Grid>
            <Grid item md={6}>
              <img
                src={ServicesBanner}
                alt="AVA Services Banner"
                style={{ maxWidth: "100%" }}
              ></img>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box
        component="div"
        sx={{
          background: "#ddd",
          width: "100%",
          height: "1px",
        }}
      ></Box>
      <Container maxWidth="xl" sx={{ paddingY: "48px" }}>
        <Grid container spacing={8}>
          <Grid item md={6} sx={{ textAlign: "right" }}>
            <img src={Guidance} alt=""></img>
          </Grid>
          <Grid item md={6}>
            <Typography
              variant="h1"
              sx={{
                color: "#000",
                fontSize: "24px",
                fontWeight: 500,
                fontFamily: "Poppins, sans-serif",
              }}
            >
              Career Assessment and Guidance
              <Box
                component="div"
                sx={{
                  background: "#FFAE42",
                  width: "45%",
                  height: "4px",
                  marginY: "24px",
                }}
              ></Box>
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontSize: "24px",fontFamily: "Poppins, sans-serif", fontWeight: 300, color: "#000" }}
            >
              Unsure about your career direction? Our experienced consultants
              will assess your skills, interests, and goals to provide
              personalized guidance and help you make informed career choices.
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Box component="div" sx={{ bgcolor: "#0C3DC4" }}>
        <Container maxWidth="xl" sx={{ paddingY: "48px" }}>
          <Grid container spacing={8}>
            <Grid item md={6} sx={{ textAlign: "right" }}>
              <Typography
                variant="h1"
                sx={{
                  color: "#FFF",
                  fontSize: "24px",
                  fontWeight: 500,
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Resume Optimization
                <Box
                  component="div"
                  sx={{
                    background: "#FFAE42",
                    width: "45%",
                    height: "4px",
                    marginY: "24px",
                    marginLeft: "auto",
                  }}
                ></Box>
              </Typography>
              <Typography
                variant="h6"
                sx={{ fontSize: "24px",fontFamily: "Poppins, sans-serif", fontWeight: 300, color: "#FFF" }}
              >
                Your resume is your first impression. Our experts will craft a
                standout resume that highlights your strengths and experiences,
                increasing your chances of catching the recruiter's eye.
              </Typography>
            </Grid>
            <Grid item md={6}>
              <img src={Optimization} alt=""></img>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Container maxWidth="xl" sx={{ paddingY: "48px" }}>
        <Grid container spacing={8}>
          <Grid item md={6} sx={{ textAlign: "right" }}>
            <img src={Preparation} alt=""></img>
          </Grid>
          <Grid item md={6}>
            <Typography
              variant="h1"
              sx={{
                color: "#000",
                fontSize: "24px",
                fontWeight: 500,
                fontFamily: "Poppins, sans-serif",
              }}
            >
              Interview Preparation
              <Box
                component="div"
                sx={{
                  background: "#FFAE42",
                  width: "45%",
                  height: "4px",
                  marginY: "24px",
                }}
              ></Box>
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontSize: "24px",fontFamily: "Poppins, sans-serif", fontWeight: 300, color: "#000" }}
            >
              Ace your interviews with confidence. Our interview coaching
              sessions will equip you with the skills to effectively communicate
              your value to potential employers.
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Box component="div" sx={{ bgcolor: "#0C3DC4" }}>
        <Container maxWidth="xl" sx={{ paddingY: "48px" }}>
          <Grid container spacing={8}>
            <Grid item md={6} sx={{ textAlign: "right" }}>
              <Typography
                variant="h1"
                sx={{
                  color: "#FFF",
                  fontSize: "24px",
                  fontWeight: 500,
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Job Search Assistance
                <Box
                  component="div"
                  sx={{
                    background: "#FFAE42",
                    width: "45%",
                    height: "4px",
                    marginY: "24px",
                    marginLeft: "auto",
                  }}
                ></Box>
              </Typography>
              <Typography
                variant="h6"
                sx={{ fontSize: "24px", fontFamily: "Poppins, sans-serif",fontWeight: 300, color: "#FFF" }}
              >
                Navigating the job market can be overwhelming. We'll assist you
                in finding the right job openings that align with your expertise
                and aspirations.
              </Typography>
            </Grid>
            <Grid item md={6}>
              <img src={JobSearch} alt=""></img>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Container maxWidth="xl" sx={{ paddingY: "48px" }}>
        <Grid container spacing={8}>
          <Grid item md={6} sx={{ textAlign: "right" }}>
            <img src={Strategies} alt=""></img>
          </Grid>
          <Grid item md={6}>
            <Typography
              variant="h1"
              sx={{
                color: "#000",
                fontSize: "24px",
                fontWeight: 500,
                fontFamily: "Poppins, sans-serif",
              }}
            >
              Networking Strategies
              <Box
                component="div"
                sx={{
                  background: "#FFAE42",
                  width: "45%",
                  height: "4px",
                  marginY: "24px",
                }}
              ></Box>
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontSize: "24px",fontFamily: "Poppins, sans-serif", fontWeight: 300, color: "#000" }}
            >
              Build valuable connections in your industry. We'll provide
              strategies to enhance your networking skills and expand your
              professional circle.
            </Typography>
          </Grid>
        </Grid>
      </Container>
      <Box component="div" sx={{ bgcolor: "#0C3DC4" }}>
        <Container maxWidth="xl" sx={{ paddingY: "48px" }}>
          <Grid container spacing={8}>
            <Grid item md={6} sx={{ textAlign: "right" }}>
              <Typography
                variant="h1"
                sx={{
                  color: "#FFF",
                  fontSize: "24px",
                  fontWeight: 500,
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Professional Development
                <Box
                  component="div"
                  sx={{
                    background: "#FFAE42",
                    width: "45%",
                    height: "4px",
                    marginY: "24px",
                    marginLeft: "auto",
                  }}
                ></Box>
              </Typography>
              <Typography
                variant="h6"
                sx={{ fontSize: "24px",fontFamily: "Poppins, sans-serif", fontWeight: 300, color: "#FFF" }}
              >
                Your career journey doesn't end with a job offer. We offer
                ongoing support and resources to help you continuously develop
                and advance in your role.
              </Typography>
            </Grid>
            <Grid item md={6}>
              <img src={Development} alt=""></img>
            </Grid>
          </Grid>
        </Container>
      </Box>

      <Container maxWidth="xl" sx={{ paddingY: "48px", textAlign: "center" }}>
        <Typography
          variant="h6"
          sx={{
            fontFamily: "Poppins, sans-serif",
            textAlign: "center",
            paddingBottom: "12px",
            color: "#000",
            fontSize: "24px",
            fontWeight: "300",
            width: "75%",
            margin: "0 auto",
          }}
        >
          Unlock your potential with AVA's comprehensive services. Let's work
          together to propel your career forward!
        </Typography>
        <Button
        component={Link}
        to="/about"
          sx={{
            color: "#FFF",
            fontSize: "14px",
            fontWeight: 600,
            bgcolor: "#0C3DC4",
            marginY: "12px",
            paddingY: "16px",
            paddingX: "40px",
            fontFamily: "Poppins, sans-serif",
            textAlign: "center",
            '&:hover': {
              backgroundColor: '#0C3DC4',
              color: '#FFF'
            },
          }}
        >
          Get Started
        </Button>
      </Container>
    </Box>
  );
}

export default Services;
