import { Box, Button, Container, Grid, Typography } from "@mui/material";
import "./About.css";
import AboutBanner from "./../../assets/images/about-banner.png";
import { Link } from "react-router-dom";

function About() {
  return (
    <Box
      sx={{
        bgcolor: "#FFF",
      }}
      component="div"
      className="about-banner"
    >
      <Box component="div" sx={{ bgcolor: "#FFF", paddingY: "48px" }}>
        <Container maxWidth="xl">
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item md={6}>
              <Typography
                variant="h6"
                sx={{
                  color: "#0C3DC4",
                  fontSize: "24px",
                  fontWeight: 500,
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                ABOUT AVA GROUP TECH
              </Typography>
              <Typography
                variant="h1"
                sx={{
                  color: "#000",
                  fontSize: "54px",
                  fontWeight: 600,
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Empowering Your Journey to Success
                <Box
                  component="div"
                  sx={{
                    background: "#FFAE42",
                    width: "35%",
                    height: "4px",
                    marginY: "12px",
                  }}
                ></Box>
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontSize: "26px",
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: 300,
                  paddingY: "12px",
                }}
              >
                At AVA Group, we believe that every individual deserves
                a fulfilling and rewarding career. Our mission is to empower
                professionals by providing them with the tools, resources, and
                guidance needed to unlock their full potential.
              </Typography>
            </Grid>
            <Grid item md={6}>
              <img
                src={AboutBanner}
                alt="AVA Services Banner"
                style={{ maxWidth: "100%" }}
              ></img>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box
        component="div"
        sx={{
          background: "#ddd",
          width: "100%",
          height: "1px",
        }}
      ></Box>

      <Container maxWidth="xl" sx={{ paddingY: "48px", textAlign: "center" }}>
        <Typography
          variant="h1"
          sx={{
            color: "#0C3DC4",
            fontSize: "36px",
            fontWeight: 600,
            fontFamily: "Poppins, sans-serif",
          }}
        >
          WHO ARE WE?
          <Box
            component="div"
            sx={{
              background: "#FFAE42",
              width: "20%",
              height: "4px",
              margin: "24px auto",
              textAlign: "center",
            }}
          ></Box>
        </Typography>
        <Typography
          variant="h6"
          sx={{
            fontSize: "20px",
            fontWeight: 300,
            width: "60%",
            margin: "0 auto",
            fontFamily: "Poppins, sans-serif",
            color: "#000",
          }}
        >
          Founded by industry veterans, AVA Group has been a trusted
          partner for countless individuals on their career journeys. Our team
          comprises experienced consultants with a passion for helping others
          succeed.
        </Typography>
      </Container>
      <Box component="div" sx={{ bgcolor: "#0C3DC4" }}>
        <Container maxWidth="xl" sx={{ paddingY: "48px", textAlign: "center" }}>
          <Typography
            variant="h1"
            sx={{
              color: "#FFF",
              fontSize: "36px",
              fontWeight: 600,
              fontFamily: "Poppins, sans-serif",
            }}
          >
            OUR APPROACH
            <Box
              component="div"
              sx={{
                background: "#FFAE42",
                width: "20%",
                height: "4px",
                margin: "24px auto",
                textAlign: "center",
              }}
            ></Box>
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontSize: "20px",
              fontWeight: 300,
              width: "60%",
              margin: "0 auto",
              color: "#FFF",
              fontFamily: "Poppins, sans-serif",
            }}
          >
            We understand that each person's career path is unique. That's why
            we take a personalized approach to every client, tailoring our
            services to their individual needs and aspirations. Whether you're
            just starting out, looking to switch careers, or aiming for career
            growth, we're here to guide you.{" "}
          </Typography>
        </Container>
      </Box>
      <Container maxWidth="xl" sx={{ paddingY: "48px", textAlign: "center" }}>
        <Typography
          variant="h1"
          sx={{
            color: "#0C3DC4",
            fontSize: "36px",
            fontWeight: 600,
            fontFamily: "Poppins, sans-serif",
          }}
        >
          OUR VISION
          <Box
            component="div"
            sx={{
              background: "#FFAE42",
              width: "20%",
              height: "4px",
              margin: "24px auto",
              textAlign: "center",
            }}
          ></Box>
        </Typography>
        <Typography
          variant="h6"
          sx={{
            fontSize: "20px",
            fontWeight: 300,
            width: "60%",
            margin: "0 auto",
            fontFamily: "Poppins, sans-serif",
            color: "#000",
          }}
        >
          Our vision is a world where every professional is engaged in work that
          aligns with their passions and skills. We strive to bridge the gap
          between talent and opportunity, making the job search process smoother
          and more rewarding.
        </Typography>
      </Container>

      <Box
        component="div"
        sx={{
          background: "#ddd",
          width: "100%",
          height: "1px",
        }}
      ></Box>
      <Container maxWidth="xl" sx={{ paddingY: "48px", textAlign: "center" }}>
        <Typography
          variant="h6"
          sx={{
            fontFamily: "Poppins, sans-serif",
            textAlign: "center",
            paddingBottom: "12px",
            color: "#000",
            fontSize: "24px",
            fontWeight: "300",
            width: "75%",
            margin: "0 auto",
          }}
        >
          Join us at AVA Group and let's embark on a journey to elevate
          your career to new heights. Your success is our ultimate goal.
        </Typography>
        <Button
        component={Link}
        to="/contact"
          sx={{
            color: "#FFF",
            fontSize: "14px",
            fontWeight: 600,
            bgcolor: "#0C3DC4",
            marginY: "12px",
            paddingY: "16px",
            paddingX: "40px",
            fontFamily: "Poppins, sans-serif",
            textAlign: "center",
            '&:hover': {
              backgroundColor: '#0C3DC4',
              color: '#FFF'
            },
          }}
        >
          CONTACT US
        </Button>
      </Container>
    </Box>
  );
}

export default About;
