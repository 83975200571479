import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import "./Contact.css";
import { useState } from "react";

function Contact() {
  const [descProb, setDescProb] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');



  const handleSubmit = (e) => {
    e.preventDefault();
    const body = `Hi AVA Group Tech,
                  I am ${firstName} ${lastName}
                  ${descProb}`
    const subject = 'Contacting us through website for queries'
    const mailtoLink = `mailto:hr@avagrouptech.com?subject=${subject}&body=${body}`;
    window.location.href = mailtoLink;
    setDescProb('');
    setEmail('')
    setFirstName('')
    setLastName('')
  };
  return (
    <Box
      sx={{
        bgcolor: "#FFF",
      }}
      component="div"
      className="about-banner"
    >
      <Box component="div" sx={{ bgcolor: "#FFF", paddingY: "48px" }}>
        <Container maxWidth="xl">
          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="center"
          >
            <Grid item md={6}>
              <Typography
                variant="h6"
                sx={{
                  color: "#0C3DC4",
                  fontSize: "24px",
                  fontWeight: 500,
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                GET INTO TOUCH
              </Typography>
              <Typography
                variant="h1"
                sx={{
                  color: "#000",
                  fontSize: "54px",
                  fontWeight: 600,
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                We're Here to Answer Your Questions
                <Box
                  component="div"
                  sx={{
                    background: "#FFAE42",
                    width: "35%",
                    height: "4px",
                    marginY: "12px",
                  }}
                ></Box>
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontSize: "24px",
                  fontFamily: "Poppins, sans-serif",
                  fontWeight: 300,
                  paddingY: "12px",
                }}
              >
                Have questions about our services or want to learn more about
                how AVA Group can help you? <br />
                <br />
                Don't hesitate to reach out to us. Our team is ready to provide
                the information you need to make informed decisions about your
                career journey.
              </Typography>
            </Grid>
            <Grid item md={6}>
              <Box
                sx={{
                  border: 1,
                  borderColor: "#ddd",
                  borderStyle: "solid",
                  padding: "32px",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontFamily: "Poppins, sans-serif",
                    fontSize: "24px",
                    fontWeight: 300,
                    color: "#000",
                    paddingBottom: '32px'
                  }}
                >
                  Let's work together to shape your future and achieve your
                  career goals with confidence!
                </Typography>
                <Box
                  component="form"
                  onSubmit={handleSubmit}
                  noValidate
                  autoComplete="off"
                >
                  <Grid container spacing={2}>
                    <Grid item md={6}>
                      <TextField
                        id="first-name"
                        fullWidth
                        label="First Name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                        variant="standard"
                      />
                    </Grid>
                    <Grid item md={6}>
                      <TextField
                        id="last-name"
                        fullWidth
                        label="Last Name"
                        variant="standard"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <TextField
                        id="email"
                        fullWidth
                        label="Email Address"
                        variant="standard"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <TextField
                        id="desc-prob"
                        fullWidth
                        label="How can we help you? Describe your problem."
                        variant="standard"
                        value={descProb}
                        onChange={(e) => setDescProb(e.target.value)}
                      />
                    </Grid>
                    <Grid item md={5}>
                      <Button
                      type="submit"
                      sx={{
                        color: "#FFF",
                        fontSize: "14px",
                        fontWeight: 600,
                        bgcolor: "#0C3DC4",
                        marginY: "12px",
                        paddingY: "16px",
                        paddingX: "40px",
                        fontFamily: "Poppins, sans-serif",
                        textAlign: "center",
                        '&:hover': {
                          backgroundColor: '#0C3DC4',
                          color: '#FFF'
                        },
                      }}
                    >
                      SEND REQUEST
                    </Button>
                    </Grid>
                  </Grid>
                  
                  
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}

export default Contact;
